import { Platform } from '../types';

export const getPlatform = (): Platform => {
  const userAgent = navigator.userAgent || window.opera;

  if (/android/i.test(userAgent)) {
    return Platform.Android;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return Platform.iOS;
  }
  return Platform.Web;
};
