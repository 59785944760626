import React, { useEffect } from 'react';

import { Platform } from '../../types';
import { getPlatform } from '../../utils/platformHelpers';
import { SLEEPIO_APP_STORE_URL, SLEEPIO_GOOGLE_PLAY_URL } from '../../utils/constants';

const AppStoreRedirector = (): React.ReactElement => {
  useEffect(() => {
    const platform = getPlatform();

    if (platform === Platform.Android) {
      window.location.href = SLEEPIO_GOOGLE_PLAY_URL;
    }
    if (platform === Platform.iOS) {
      window.location.href = SLEEPIO_APP_STORE_URL;
    }
  }, []);

  return <h1>Redirecting...</h1>;
};

export default AppStoreRedirector;
