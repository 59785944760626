import styled from 'styled-components/macro';

export const ConsentWidgetButton = styled.button`
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
    width: 30px;
`;
