enum EnvironmentEnum {
  LOCAL = 'local',
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
  PR = 'pr',
}

type EnvironmentEnumType = `${EnvironmentEnum}`;

export const envSwitch = (envOptions: Record<EnvironmentEnum, string>, overrideEnv?: EnvironmentEnumType): string => (
  envOptions[overrideEnv ?? process.env.REACT_APP_DEPLOY_ENV as EnvironmentEnum]
);
