import { Box } from '@bighealth/react-limbix-ui';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

const StoreImgWrapper = styled.a`
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    margin-top: 16px;
`;

const Title = styled(Box)`
    color: ${({ theme }) => theme.infoBox.textColor};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    word-wrap: break-word;
    margin-top: 8px;
    margin-bottom: 8px;
`;

const Subtitle = styled(Box)`
    color: ${({ theme }) => theme.infoBox.textColor};
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.3px;
    word-wrap: break-word;
`;

const QRCodeWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const QRCode = styled(QRCodeSVG)`
    align-self: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 16;
`;

const ImageWrapper = styled(Box)`
    text-align: center;
    width: 100%;
`;

export default {
  StoreImgWrapper,
  Title,
  Subtitle,
  QRCodeWrapper,
  QRCode,
  ImageWrapper,
};
