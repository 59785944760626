import React, { useCallback, useState, useEffect } from 'react';
import {
  Link, Redirect, RouteComponentProps, useParams,
} from 'react-router-dom';
import {
  Container, Snackbar, Step, StepLabel, Stepper,
} from '@material-ui/core';
import {
  AssessmentForm, LoadingSpinner, UseParamsType, Text, Box, Question,
} from '@bighealth/react-limbix-ui';
import { Alert } from '@material-ui/lab';

import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from '@apollo/client';

import {
  MutationReportParticipantAssessmentResultArgs,
  AssignmentTypeOfClient,
  AssessmentType,
} from '@/apollo/__generated__/graphql';
import {
  REPORT_PARTICIPANT_ASSESSMENT_RESULT_MUTATION,
} from '@/apollo/mutations';
import { ASSESSMENT_QUERY, ASSIGNMENTS_QUERY, ME_QUERY } from '@/apollo/queries';
import { GraphQLErrorsType } from '@/types';
import { useModal, usePreventNavigation } from '@/hooks/redux';
import { useAssignmentMutations } from '@/hooks/apollo/mutations';
import { getErrorStringKey } from '@/utils/errorUtils';
import { LocaleMap } from '@/utils/translate';

const Assessment: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const { t, i18n } = useTranslation();
  const { showModal } = useModal();
  const [, { setPreventNavigation }] = usePreventNavigation();
  const { assignmentUid } = useParams<UseParamsType>();
  const meQueryData = useQuery(ME_QUERY);
  const { startAssignment } = useAssignmentMutations();
  const [reportParticipantAssessmentResult] = useMutation<MutationReportParticipantAssessmentResultArgs>(
    REPORT_PARTICIPANT_ASSESSMENT_RESULT_MUTATION,
  );
  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationErrors, setMutationErrors] = useState<GraphQLErrorsType>(null);
  const assignmentQueryData = useQuery(
    ASSIGNMENTS_QUERY,
    {
      variables: {
        onlyVisible: true,
        clientType: AssignmentTypeOfClient.WebPortal,
      },
    },
  );

  const assessmentUid = (location?.state as { assessmentUid: string })?.assessmentUid;
  const { data: assessmentData, loading: assessmentQueryLoading, error } = useQuery(ASSESSMENT_QUERY,
    { variables: { assessmentUid } });

  useEffect(() => {
    setPreventNavigation({
      isBlocked: true,
      message: t('assessment.leave'),
    });
  }, []);

  const currentAssignment = assignmentQueryData?.data?.assignments
    ?.find((assignment) => assignment?.uid === assignmentUid);

  const currentAssignmentIsComplete = !!currentAssignment?.completeTime;
  const currentAssignmentCanSkipQuestions = currentAssignment?.canSkipQuestions;
  const pdtUid = currentAssignment?.pdt?.uid || '';

  useEffect(() => {
    if (currentAssignmentIsComplete) {
      setPreventNavigation({
        isBlocked: false,
      });
    }
  }, [currentAssignmentIsComplete]);

  const renderSkippedQuestionsModal = useCallback((
    skippedQuestions: Question[],
    onSkip: () => void,
    onReturn?: () => void,
  ) => {
    const questionsMissingAnswers = skippedQuestions.map((question) => (
      <div key={question.uid}>
        <span>{question.text}</span>
        <span style={{ color: 'red' }}>{question.isOptional ? '' : ' *'}</span>
      </div>
    ));
    showModal(
      'GENERIC_WARNING',
      {
        headerText: t('AF.skip.refuse'),
        body: questionsMissingAnswers,
        primaryButtonText: t('AF.skip.return'),
        secondaryButtonText: t('AF.skip.submit'),
        onClickPrimaryButton: onReturn,
        onClickSecondaryButton: onSkip,
      },
    );
  }, []);

  const renderCompletionPage = useCallback(() => (
    <Box key={1}>
      <Box marginBottom="48px" alignContent="center">
        <Text as="h2">
          {t('assessment.thanks')}
        </Text>
      </Box>
      <Link to="/">
        {t('assessment.back')}
      </Link>
    </Box>
  ), []);

  const isLoading = meQueryData.loading || assignmentQueryData.loading || assessmentQueryLoading || mutationLoading;

  if (!assessmentUid) {
    return <Redirect to="/" />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <Box>
        {t(getErrorStringKey([error]))}
      </Box>
    );
  }

  const handleStart = async () => {
    const result = await startAssignment({
      variables: {
        assignmentUid,
        startTime: new Date(),
      },
    });
    if (result.errors && result.errors.length > 0) {
      setMutationErrors(result.errors);
    }
  };

  const handleSubmit = async (assessmentAnswers: string[]) => {
    setMutationLoading(true);
    const result = await reportParticipantAssessmentResult({
      variables: {
        pdtUid,
        assessmentUid: assessmentData.assessment.uid,
        assessmentAnswers,
        assignmentUid,
        assignmentCompleteTime: new Date(),
      },
      refetchQueries: [{
        query: ASSIGNMENTS_QUERY,
        variables: {
          onlyVisible: true,
          clientType: AssignmentTypeOfClient.WebPortal,
        },
      }],
    });
    if (result.errors && result.errors.length > 0) {
      setMutationErrors(result.errors);
    }
    setMutationLoading(false);
    return result;
  };

  const renderAssessmentForm = (assessment: AssessmentType) => (
    <Container>
      {
        assessment
                    && (
                      <AssessmentForm
                        assessment={assessment}
                        onSubmit={handleSubmit}
                        onStart={handleStart}
                        cancelButtonText={t('AF.cancel')}
                        submitButtonText={t('AF.submit')}
                        canSkipQuestions={currentAssignmentCanSkipQuestions}
                        onSubmitWithSkippedQuestions={renderSkippedQuestionsModal}
                        fieldRequiredText={t('assessment.this_field_is_required')}
                        locale={LocaleMap[i18n.language]}
                      />
                    )
      }
      <Snackbar
        open={!!mutationErrors}
        onClose={() => { setMutationErrors(null); }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error">
          {t(getErrorStringKey(mutationErrors || []))}
        </Alert>
      </Snackbar>
    </Container>
  );

  const currentStep = currentAssignmentIsComplete ? 1 : 0;
  return (
    <Box alignContent="center">
      <Stepper activeStep={currentStep}>
        <Step>
          <StepLabel>
            {t('assessment.questions')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {t('assessment.complete')}
          </StepLabel>
        </Step>
      </Stepper>
      <Box marginTop="32px" marginLeft="32px" marginBottom="64px">
        {
          assessmentData.assessment && (currentAssignmentIsComplete
            ? renderCompletionPage()
            : renderAssessmentForm(assessmentData.assessment as AssessmentType))
        }
      </Box>
    </Box>
  );
};

export default Assessment;
