import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Box, Text } from '@bighealth/react-limbix-ui';

import Styled from './ResetPasswordForm.styles';

import history from '@/utils/history';
import { useMutation } from '@/hooks/apollo';
import { UPDATE_PASSWORD } from '@/apollo/mutations';
import ResetPasswordFormPageGraphic from '@/assets/ResetPasswordFormPageGraphic.svg';
import { MutationUpdatePasswordArgs } from '@/apollo/__generated__/graphql';
import { GENERIC_ERROR_STRING_KEY, getErrorStringKey } from '@/utils/errorUtils';

type PasswordResetParams = {
  userUid: string;
  token: string;
};

const ResetPasswordForm : React.FC = () => {
  const { userUid, token } = useParams<PasswordResetParams>();
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [errorString, setErrorString] = useState<string>();
  const [resetPassword] = useMutation<MutationUpdatePasswordArgs>(
    UPDATE_PASSWORD,
  );
  const { t } = useTranslation();

  const sendPasswordReset = async () => {
    setLoading(true);
    const result = await resetPassword({
      variables: {
        newPassword: password,
        userUid,
        token,
      },
    });
    const { errors, data } = result;
    const redirectURL = data?.updatePassword?.redirectUrl;

    setLoading(false);
    if (errors && errors.length > 0) {
      setErrorString(t(getErrorStringKey(result.errors)));
    } else if (redirectURL) {
      history.push(
        '/reset-password-form-redirect',
        { redirectURL },
      );
    } else {
      // no redirect URL returned - show generic error
      setErrorString(t(GENERIC_ERROR_STRING_KEY));
    }
  };

  const submissionDisabled = loading || !password || password !== passwordConfirmation;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !submissionDisabled) {
      sendPasswordReset();
    }
  };

  return (
    <Styled.MainContainer>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        minHeight="100vh"
        width="100%"
      >
        <Styled.BackgroundGraphic src={ResetPasswordFormPageGraphic} alt="reset-password-graphic" />
      </Box>
      <Styled.FormContainer>
        <Box marginBottom="12px">
          <Text textAlign="center" fontSize="24px" lineHeight="32px" fontWeight={700}>
            {t('reset_password_form.enter_new_password')}
          </Text>
        </Box>
        <Box marginBottom="32px">
          <Text textAlign="center" fontSize="14px" lineHeight="20px" fontWeight={400}>
            {t('reset_password_form.blurb')}
          </Text>
        </Box>
        <Styled.StyledForm noValidate>
          <TextField
            style={{ marginBottom: 32 }}
            variant="outlined"
            margin="normal"
            type="password"
            required
            fullWidth
            id="password"
            label={t('reset_password_form.password')}
            name="password"
            value={password}
            autoFocus
            onKeyDown={handleKeyDown}
            onChange={(event) => setPassword(event.target.value?.trim())}
          />
          <TextField
            style={{ marginBottom: 32 }}
            variant="outlined"
            margin="normal"
            type="password"
            required
            fullWidth
            id="passwordConfirmation"
            label={t('reset_password_form.confirm_password')}
            name="passwordConfirmation"
            value={passwordConfirmation}
            autoFocus
            onKeyDown={handleKeyDown}
            onChange={(event) => setPasswordConfirmation(event.target.value?.trim())}
          />
          <Text color="red">{errorString}</Text>
          <Styled.SubmitButton
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={sendPasswordReset}
            disabled={submissionDisabled}
          >
            {t('reset_password_form.submit')}
          </Styled.SubmitButton>
        </Styled.StyledForm>
      </Styled.FormContainer>
    </Styled.MainContainer>
  );
};

export default ResetPasswordForm;
