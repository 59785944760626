import { gql } from '@apollo/client';

import useMutation from '@/hooks/apollo/useMutation';

import { MutationCompleteAssignmentArgs } from '@/apollo/__generated__/graphql';
import { MutationFunction } from '@/types';

const useCompleteAssignmentMutation = (): MutationFunction<MutationCompleteAssignmentArgs> => {
  const COMPLETE_ASSIGNMENT_MUTATION = gql`
  mutation($assignmentUid: String!, $completeTime:DateTime!, $pdtUid: String) {
      completeAssignment(assignmentUid: $assignmentUid, completeTime: $completeTime, pdtUid: $pdtUid) {
          assignment {
              uid
          }
      }
  }
`;
  const [completeAssignment] = useMutation<MutationCompleteAssignmentArgs>(
    COMPLETE_ASSIGNMENT_MUTATION,
  );

  return completeAssignment;
};

export default useCompleteAssignmentMutation;
