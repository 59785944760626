import React, { useContext, useState } from 'react';
import { Text, Box } from '@bighealth/react-limbix-ui';
import { TextField } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { CTAButton } from '../../components/CTAButton/CTAButton';
import { DataSharingInfo } from '../../components/DataSharingInfo/DataSharingInfo';
import { Titles } from '../../components/Titles/Titles';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';
import CoverageRejection from '../CoverageRejection';

import { OverrideToggle } from '../../components/OverrideToggle/OverrideToggle';

import { getPdtUid, getProductId, getProductMinAge } from '../../utils/productHelpers';

import { OnboardingParams } from '../../types';

import { SleepioCoverageRejection } from '../CoverageRejection/SleepioCoverageRejection';

import { ErrorText } from '../../components/ErrorText/ErrorText';

import {
  getAge, validateDOB, validateZipCode,
} from '@/utils/stringValidators';
import { CHECK_ELIGIBILITY_QUERY } from '@/apollo/queries';
import { convertDOBToDateString } from '@/utils/stringUtils';
import { addUserProperties } from '@/utils/heap';

export const CheckCoverage: React.FC = () => {
  const [overrideEligibilityCheck, setOverrideEligibilityCheck] = useState(false);
  const [returnIneligibleMessage, setReturnIneligibleMessage] = useState(false);
  const { product, slug } = useParams<OnboardingParams>();

  const {
    next,
    updateFormData,
    onboardingFormData,
    eligibilityFormCustomization: { showEmployeeId, employeeIdFieldRename, showZipCode },
    uid,
  } = useContext(OnboardingFlowContext);
  const [checkEligibility, { loading }] = useLazyQuery(CHECK_ELIGIBILITY_QUERY);
  const [isEligible, setIsEligible] = useState(undefined);
  const { t } = useTranslation();

  // validate user input
  const formValidated = onboardingFormData.patientFirstName !== ''
    && onboardingFormData.patientLastName !== ''
    && validateDOB(onboardingFormData.patientDateOfBirth)
    && (onboardingFormData.employeeId !== '' || !showEmployeeId)
    && (showZipCode ? validateZipCode(onboardingFormData.patientZipCode) : true);

  // validate each text field individually. used for error states and helper text...
  const [patientFirstNameFieldError, setPatientFirstNameFieldError] = useState(false);
  const [patientLastNameFieldError, setPatientLastNameFieldError] = useState(false);
  const [patientZipcodeFieldError, setPatientZipcodeFieldError] = useState(false);
  const [patientEmployeeIdFieldError, setPatientEmployeeIdFieldError] = useState(false);
  const [patientDOBFieldError, setPatientDOBFieldError] = useState(false);
  const [patientDOBFieldErrorMessage, setPatientDOBFieldErrorMessage] = useState('');
  const [generalErrorMessage, setGeneralErrorMessage] = useState(undefined);

  const isCaregiver = onboardingFormData.personEnrolling === 'caregiver';
  const employeeIdField = employeeIdFieldRename
    || t(`coverage.${product}.${onboardingFormData.personEnrolling}.employee_id_label`);

  const checkCoverage = async () => {
    addUserProperties({ cortex_uid: uid });
    setGeneralErrorMessage(undefined);
    if (returnIneligibleMessage) {
      setIsEligible(false);
      return;
    }
    if (overrideEligibilityCheck) {
      next();
      return;
    }
    try {
      const result = await checkEligibility({
        variables: {
          pdtUid: getPdtUid(product),
          firstName: onboardingFormData.patientFirstName,
          lastName: onboardingFormData.patientLastName,
          dateOfBirth: convertDOBToDateString(onboardingFormData.patientDateOfBirth),
          postalCode: onboardingFormData.patientZipCode,
          organizationSlug: slug,
          employeeId: showEmployeeId ? onboardingFormData.employeeId : undefined,
          uid,
          productId: getProductId(product),
        },
      });

      if (result?.data?.eligibilityStatus.isEligible) {
        // happy path, eligible
        next();
      } else if (result?.data?.eligibilityStatus.isEligible === false) {
        // happy path, not eligible
        console.warn(result.error);
        setIsEligible(false);
      } else {
        console.warn('we cannot determine whether the user is eligible.');
        setGeneralErrorMessage(t('coverage.errors.generic_error'));
      }
    } catch (e) {
      // there was a server or browser error
      console.warn('This was a browser or server error.');
      setGeneralErrorMessage(t('coverage.errors.generic_error'));
    }
  };

  if (isEligible === false) {
    const Rejection = product === 'sleepio' ? SleepioCoverageRejection : CoverageRejection;
    return <Rejection isCaregiver={isCaregiver} />;
  }
  return (
    <>
      <Titles
        title={t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_title`)}
        subtitle={t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_subtitle`)}
      />
      <Titles
        title={t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_form_title`)}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientFirstNameFieldError}
        helperText={patientFirstNameFieldError ? t('coverage.errors.first_name_error') : ''}
        label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.f_name_label`)}
        id="coverageFormFirstName"
        inputProps={{
          placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.f_name_label`),
        }}
        value={onboardingFormData.patientFirstName}
        onChange={(e) => {
          setPatientFirstNameFieldError(false);
          updateFormData({ patientFirstName: e.target.value });
        }}
        onBlur={(e) => {
          setPatientFirstNameFieldError(e.target.value === '');
        }}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientLastNameFieldError}
        helperText={patientLastNameFieldError ? t('coverage.errors.last_name_error') : ''}
        label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.l_name_label`)}
        id="coverageFormLastName"
        inputProps={{
          placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.l_name_label`),
        }}
        value={onboardingFormData.patientLastName}
        onChange={(e) => {
          setPatientLastNameFieldError(false);
          updateFormData({ patientLastName: e.target.value });
        }}
        onBlur={(e) => {
          setPatientLastNameFieldError(e.target.value === '');
        }}
      />
      {showZipCode
        && (
          <TextField
            className="input-field"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            error={patientZipcodeFieldError}
            helperText={patientZipcodeFieldError ? t('coverage.errors.zipcode_error') : ''}
            label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.zipcode_label`)}
            id="coverageFormZipCode"
            inputProps={{
              placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.zipcode_label`),
            }}
            value={onboardingFormData.patientZipCode}
            onChange={(e) => {
              setPatientZipcodeFieldError(false);
              updateFormData({ patientZipCode: e.target.value });
            }}
            onBlur={(e) => {
              setPatientZipcodeFieldError(!validateZipCode(e.target.value));
            }}
          />
        )}
      {showEmployeeId && (
        <TextField
          className="input-field"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          error={patientEmployeeIdFieldError}
          helperText={
            patientEmployeeIdFieldError ? `${t('coverage.errors.employee_id_error')} ${employeeIdField}` : ''
          }
          label={
            employeeIdField
          }
          id="coverageFormEmployeeId"
          inputProps={{
            placeholder: employeeIdField,
          }}
          value={onboardingFormData.employeeId}
          onChange={(e) => {
            setPatientEmployeeIdFieldError(false);
            updateFormData({ employeeId: e.target.value });
          }}
          onBlur={(e) => {
            setPatientEmployeeIdFieldError(e.target.value === '');
          }}
        />
      )}
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientDOBFieldError}
        helperText={patientDOBFieldErrorMessage}
        label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.dob_label`)}
        id="coverageFormDOB"
        inputProps={{
          placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.dob_label`),
        }}
        value={onboardingFormData.patientDateOfBirth}
        onChange={(e) => {
          setPatientDOBFieldError(false);
          setPatientDOBFieldErrorMessage('');
          updateFormData({ patientDateOfBirth: e.target.value });
          if (validateDOB(e.target.value)) {
            if (getAge(e.target.value) < getProductMinAge(product)) {
              setPatientDOBFieldErrorMessage(
                t(`coverage.${product}.${onboardingFormData.personEnrolling}.dob_age_error`),
              );
              setPatientDOBFieldError(true);
            }
          }
        }}
        onBlur={(e) => {
          if (validateDOB(e.target.value)) {
            if (getAge(e.target.value) < getProductMinAge(product)) {
              setPatientDOBFieldErrorMessage(
                t(`coverage.${product}.${onboardingFormData.personEnrolling}.dob_age_error`),
              );
              setPatientDOBFieldError(true);
            }
          } else {
            setPatientDOBFieldErrorMessage(t('coverage.errors.dob_error'));
            setPatientDOBFieldError(true);
          }
        }}
      />

      <Box
        style={{
          marginLeft: '14px',
        }}
      >
        <Text
          fontFamily="GT Walsheim"
          fontSize="14px"
          fontWeight="400"
        >
          MM / DD / YYYY
        </Text>
      </Box>
      {generalErrorMessage && (
        <ErrorText error={generalErrorMessage} />
      )}
      <CTAButton
        text={t('coverage.check_coverage')}
        handleClick={checkCoverage}
        isDisabled={formValidated === false || loading}
        showLoading={loading}
      />
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <DataSharingInfo />
      </Box>

      {process.env.NODE_ENV !== 'production' && (
        <>
          <OverrideToggle
            userMessage="Override Eligibility Check"
            override={setOverrideEligibilityCheck}
          />
          <OverrideToggle
            userMessage="Force Eligibility Check Failure"
            override={setReturnIneligibleMessage}
          />
        </>
      )}
    </>
  );
};
