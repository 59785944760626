import { sha256 } from 'js-sha256';

import { envSwitch } from '@/utils/environmentUtils';

const heapId = envSwitch({
  local: '',
  dev: '3070449590',
  pr: '3070449590',
  stage: '3070449590',
  prod: '4157575057',
});

export const initializeHeap = () => {
  if (window?.heap?.load && heapId) {
    window.heap.load(heapId);
  }
};

export const identifyUser = (email: string) => {
  if (window?.heap?.identify) {
    const hashedEmail = sha256(email);
    window.heap.identify(hashedEmail);
  }
};

export const addUserProperties = (properties: Record<string, string>) => {
  if (window?.heap?.addUserProperties) {
    window.heap.addUserProperties(properties);
  }
};
