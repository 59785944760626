import { gql } from '@apollo/client';

import useMutation from '@/hooks/apollo/useMutation';

import { MutationStartAssignmentArgs } from '@/apollo/__generated__/graphql';
import { MutationFunction } from '@/types';

const useStartAssignmentMutation = (): MutationFunction<MutationStartAssignmentArgs> => {
  const START_ASSIGNMENT_MUTATION = gql`
  mutation($assignmentUid: String!, $startTime:DateTime!, $pdtUid: String) {
      startAssignment(assignmentUid: $assignmentUid, startTime: $startTime, pdtUid: $pdtUid) {
          assignment {
              uid
          }
      }
    }
  `;

  const [startAssignment] = useMutation<MutationStartAssignmentArgs>(START_ASSIGNMENT_MUTATION);

  return startAssignment;
};

export default useStartAssignmentMutation;
