import { Product } from '../types';

import { sparkTheme } from '../themes/sparkTheme';
import { sleepioTheme } from '../themes/sleepioTheme';

import { SLEEPIO_PDT_UID, SPARKRX_PDT_UID } from '@/utils/constants';

export const getProductName = (product: Product) => {
  switch (product) {
  case 'spark':
    return 'Spark Direct';
  default:
    return 'Sleepio';
  }
};

export const getPdtUid = (product: Product) => {
  switch (product) {
  case 'spark':
    return SPARKRX_PDT_UID;
  default:
    return SLEEPIO_PDT_UID;
  }
};

export const getProductId = (product: Product) => {
  switch (product) {
  case 'spark':
    return 4;
  default:
    return 1;
  }
};

export const getTheme = (product: Product) => {
  switch (product) {
  case 'spark':
    return sparkTheme;
  default:
    return sleepioTheme;
  }
};

export const getProductEmail = (product: Product) => {
  switch (product) {
  case 'spark':
    return 'spark@bighealth.com';
  default:
    return 'sleepio@bighealth.com';
  }
};

export const getProductTermsAndConditions = (product: Product) => {
  switch (product) {
  case 'spark':
    return 'https://www.bighealth.com/spark-direct-terms-and-conditions/';
  default:
    return 'https://privacy.bighealth.com';
  }
};

export const getProductPrivacyPolicy = (product: Product) => {
  switch (product) {
  case 'spark':
    return 'https://www.bighealth.com/spark-direct-privacy-policy/';
  default:
    return 'https://privacy.bighealth.com/policies';
  }
};

export const getProductMinAge = (product: Product) => {
  switch (product) {
  case 'spark':
    return 13;
  default:
    return 18;
  }
};

export const setSiteTitleAndIcon = (product: Product) => {
  document.title = product === 'spark' ? 'Spark Coverage Portal' : 'Sleepio Coverage Portal';
  const favicon = document.querySelector("link[rel~='icon']");
  const baseUrl = process.env.PUBLIC_URL;
  const faviconUrl = product === 'spark' ? `${baseUrl}/favicon-spark.png` : `${baseUrl}/favicon-sleepio.png`;
  favicon.setAttribute('href', faviconUrl);
};
