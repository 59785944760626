import { isValidPhoneNumber } from 'react-phone-number-input';

import { OnboardingFormData } from '../../defaultFormData';
import { EligibilityFormCustomization } from '../../OnboardingFlowContext';
import { Product } from '../../types';

import { validateEmail } from '@/utils/stringValidators';

const validatePatientInfoForm = (
  onboardingFormData: Partial<OnboardingFormData>,
  eligibilityConfig: EligibilityFormCustomization,
  product: Product,
): boolean => {
  const isValidAddress = onboardingFormData.patientStreetAddress !== ''
    && onboardingFormData.patientCity !== ''
    && onboardingFormData.patientState !== '';

  const isValidPatientData = validateEmail(onboardingFormData.patientEmail)
    && isValidPhoneNumber(onboardingFormData.patientPhoneNumber);

  // at a minimum, we validate patient data amd terms
  let isValidForm = isValidPatientData;

  // validate address for orgs configured to show address fields
  if (eligibilityConfig.showAddressField) {
    isValidForm = isValidForm && isValidAddress;
  }

  // check terms if a patient is enrolling
  if (onboardingFormData.personEnrolling === 'patient') {
    isValidForm = isValidForm && onboardingFormData.policyAndTermsAcknowledgement === true;
  }

  // only check safetyAcknowledgement for non-sleepio products
  if (onboardingFormData.personEnrolling === 'patient' && product !== 'sleepio') {
    isValidForm = isValidForm && onboardingFormData.safetyAcknowledgement === true;
  }

  return isValidForm;
};

export default validatePatientInfoForm;
