import { useEffect } from 'react';

import { initializeHeap } from '../utils/heap';

import { envSwitch } from '@/utils/environmentUtils';

const initializeConsentChanged = () => {
  if (window.airgap?.getConsent) {
    const consent = window.airgap.getConsent();
    if (consent.purposes.Analytics === true) {
      initializeHeap();
    }
  } else {
    console.log('Consent not available');
  }
  window.airgap?.addEventListener('consent-change', ({ detail: { consent } }) => {
    if (consent.purposes.Analytics === true) {
      initializeHeap();
    } else if (window?.heap?.loaded) {
      window.location.reload();
    }
  });
};

const consentSrc = envSwitch({
  local: '',
  pr: 'https://consent-modules.bighealth.com/cm-test/a46eb19b-d9f8-442a-a73b-2d1dc8111b1b/airgap.js',
  dev: 'https://consent-modules.bighealth.com/cm-test/a46eb19b-d9f8-442a-a73b-2d1dc8111b1b/airgap.js',
  stage: 'https://consent-modules.bighealth.com/cm-test/a46eb19b-d9f8-442a-a73b-2d1dc8111b1b/airgap.js',
  prod: 'https://consent-modules.bighealth.com/cm/a46eb19b-d9f8-442a-a73b-2d1dc8111b1b/airgap.js',
});

const dataSyncEndpoint = envSwitch({
  local: '',
  pr: 'https://consent-sync.bighealth.com/consent-manager/a46eb19b-d9f8-442a-a73b-2d1dc8111b1b',
  dev: 'https://consent-sync.bighealth.com/consent-manager/a46eb19b-d9f8-442a-a73b-2d1dc8111b1b',
  stage: 'https://consent-sync.bighealth.com/consent-manager/a46eb19b-d9f8-442a-a73b-2d1dc8111b1b',
  prod: 'https://consent-sync.bighealth.com/consent-manager/a46eb19b-d9f8-442a-a73b-2d1dc8111b1b',
});

const dataSite = envSwitch({
  local: '',
  pr: 'bighealthdev.com',
  dev: 'bighealthdev.com',
  stage: 'bighealthstage.com',
  prod: 'bighealth.com',
});

export const useConsent = () => {
  useEffect(() => {
    if (consentSrc && !document.getElementById('consent-manager')) {
      const consentScript = document.createElement('script');
      consentScript.id = 'consent-manager';
      consentScript.async = true;
      consentScript.src = consentSrc;
      consentScript.setAttribute('data-cfasync', 'false');
      consentScript.setAttribute('data-local-sync', 'allow-network-observable');
      consentScript.setAttribute('data-site', dataSite);
      consentScript.setAttribute('data-prompt', 'auto');
      consentScript.setAttribute('data-sync-endpoint', dataSyncEndpoint);
      consentScript.onload = () => {
        initializeConsentChanged();
      };
      // eslint-disable-next-line max-len
      document.getElementsByTagName('script')[0].parentNode?.insertBefore(consentScript, document.getElementsByTagName('script')[0]);
    } else {
      initializeConsentChanged();
    }
  }, []);
};
