import {
  OperationVariables,
  DocumentNode,
  useMutation as apolloUseMutation,
} from '@apollo/client';

import { Mutation } from '@/apollo/__generated__/graphql';
import { MutationTuple, MutationHookOptions } from '@/types';

const useMutation = <T extends OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<T>,
): MutationTuple<T> => apolloUseMutation<Mutation, T>(mutation, options);

export default useMutation;
