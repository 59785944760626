import useCompleteAssignmentMutation from './useCompleteAssignmentMutation';
import useStartAssignmentMutation from './useStartAssignmentMutation';

import { MutationCompleteAssignmentArgs, MutationStartAssignmentArgs } from '@/apollo/__generated__/graphql';
import { MutationFunction } from '@/types';

type AssignmentMutations = {
  startAssignment: MutationFunction<MutationStartAssignmentArgs>
  completeAssignment: MutationFunction<MutationCompleteAssignmentArgs>
}
const useAssignmentMutations = ():AssignmentMutations => {
  const completeAssignment = useCompleteAssignmentMutation();
  const startAssignment = useStartAssignmentMutation();
  return { startAssignment, completeAssignment };
};

export default useAssignmentMutations;
