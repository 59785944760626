import React from 'react';
import { Text } from '@bighealth/react-limbix-ui';

import { useParams } from 'react-router-dom';

import Styled from './Footer.styles';
import { OnboardingParams } from '../../types';
import { getProductName } from '../../utils/productHelpers';

export const Footer: React.FC = () => {
  const { product } = useParams<OnboardingParams>();
  const currentYear = new Date().getFullYear();

  return (
    <Styled.FooterContainer>
      <Text
        color="#6A717D"
        fontSize="14px"
        textAlign="center"
      >
        ©
        {' '}
        {currentYear}
        {' '}
        Big Health
      </Text>
      <Text
        color="#6A717D"
        fontSize="14px"
        textAlign="center"
      >
        {getProductName(product)}
        {' '}
        Onboarding Version
        {` ${process.env.APP_VERSION}`}
      </Text>
    </Styled.FooterContainer>
  );
};
