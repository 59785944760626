import React from 'react';

import { ConsentWidgetButton, Icon } from './ConsentWidget.styled';

import GearIcon from '@/assets/GearSVG.svg';

export const ConsentWidget: React.FC = () => {
  const onClick = () => {
    window.transcend?.toggleConsentManager();
  };

  return (
    <ConsentWidgetButton onClick={onClick}>
      <Icon src={GearIcon} />
    </ConsentWidgetButton>
  );
};
