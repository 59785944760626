export type Product = 'sleepio' | 'spark';

export type OnboardingParams = {
    slug: string;
    product: Product;
}

export enum Platform {
    Android = 'Android',
    iOS = 'iOS',
    Web = 'Web'
}
