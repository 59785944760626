import React, { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { OnboardingFlowContext } from '../../OnboardingFlowContext';

import { OnboardingParams, Platform } from '../../types';

import { getPlatform } from '../../utils/platformHelpers';
import Styled from './ReadyToStartSleepio.styles';

import { SLEEPIO_APP_STORE_URL, SLEEPIO_GOOGLE_PLAY_URL } from '../../utils/constants';

import EmoCloudSleep from '@/assets/EmoCloudSleep.svg';
import AppStore from '@/assets/AppStore.svg';
import GooglePlay from '@/assets/GooglePlay.svg';

export const ReadyToStartSleepio: React.FC = () => {
  const { onboardingFormData } = useContext(OnboardingFlowContext);
  const { product } = useParams<OnboardingParams>();
  const { t } = useTranslation();
  const platform = getPlatform();

  let storeLink;
  let title = t(`coverage.${product}.${onboardingFormData.personEnrolling}.download_sleepio_title`);
  let subtitle1 = t(`coverage.${product}.${onboardingFormData.personEnrolling}.download_sleepio_subtitle_1`);
  let subtitle2 = '';

  if (platform === Platform.Android) {
    storeLink = (
      <Styled.StoreImgWrapper href={SLEEPIO_GOOGLE_PLAY_URL}>
        <img
          alt="Play Store"
          src={GooglePlay}
        />
      </Styled.StoreImgWrapper>
    );
  } else if (platform === Platform.iOS) {
    storeLink = (
      <Styled.StoreImgWrapper href={SLEEPIO_APP_STORE_URL}>
        <img
          alt="App Store"
          src={AppStore}
        />
      </Styled.StoreImgWrapper>
    );
  } else {
    storeLink = (
      <Styled.QRCodeWrapper>
        <Styled.QRCode
          data-testid="qr-code"
          value={`${window.location.origin}/appstore/sleepio`}
        />
      </Styled.QRCodeWrapper>
    );
    title = t(`coverage.${product}.${onboardingFormData.personEnrolling}.download_sleepio_title_desktop`);
    subtitle1 = t(`coverage.${product}.${onboardingFormData.personEnrolling}.download_sleepio_subtitle_1_desktop`);
    subtitle2 = t(`coverage.${product}.${onboardingFormData.personEnrolling}.download_sleepio_subtitle_2_desktop`);
  }

  return (
    <>
      <Styled.ImageWrapper>
        <img
          alt="Emlo Cloud Sleep"
          style={{ width: '206px' }}
          src={EmoCloudSleep}
        />
      </Styled.ImageWrapper>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Subtitle>
        {subtitle1}
      </Styled.Subtitle>
      {storeLink}
      <Styled.Subtitle>{subtitle2}</Styled.Subtitle>
    </>
  );
};
