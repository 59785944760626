import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  LoadingSpinner, Text,
} from '@bighealth/react-limbix-ui';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Styled from './KaiserSignupPage.styles';
import { KaiserSignupFormType } from './KaiserSignupForm';
import VerifyEmailMessage from './VerifyEmailMessage';
import AccountCreatedMessage from './AccountCreatedMessage';
import InvalidURLMessage from './InvalidURLMessage';
import AccessRenewedMessage from './AccessRenewedMessage';
import LabelingText from './LabelingText';

import { SPARKRX_PDT_UID } from '@/utils/constants';
import { useMutation } from '@/hooks/apollo';
import KaiserSignupBackgroundGraphic from '@/assets/KaiserSignupBackgroundGraphic.svg';
import KaiserPermanenteLogo from '@/assets/KaiserPermanenteLogo.svg';
import SparkDirectLogo from '@/assets/SparkDirectLogo.svg';
import {
  MutationDecodeOrganizationSignupTokenArgs, MutationExternalOrganizationAccessRenewalArgs,
  MutationExternalOrganizationSignupArgs,
} from '@/apollo/__generated__/graphql';

// KaiserPayloadType describes the payload of the signup token received from Kaiser
type KaiserPayloadType = {
  // issuer
  iss: string,
  // audience
  aud: string,
  // email
  eml: string,
  // first name
  fnm: string,
  // last name
  lnm: string,
  // unique Kaiser ID
  uid: string,
  // date of birth
  dob: string,
  // provider-defined region
  reg: string,
};

// Need this function to parse the email field because it can either be a list or a string
const parsePayloadEmail = (email: string | string[]): string => {
  if (!email) {
    return null;
  }
  const isArray = Array.isArray(email);
  if (isArray && email.length > 0) {
    return email[0];
  }
  if (!isArray) {
    return email;
  }

  return null;
};

const DECODE_TOKEN = gql`
  mutation DecodeOrganizationSignupToken($token: String!, $tokenIssuer: String!, $pdtUid: String!) {
      decodeOrganizationSignupToken(token: $token, tokenIssuer: $tokenIssuer, pdtUid: $pdtUid) {
          tokenPayload
          existingCareTeam {
            canAccessPdt
            patient {
              user {
                email
                emailVerified
              }
            }
          }
      }
  }
`;

const ORGANIZATION_SIGNUP = gql`
  mutation ExternalOrganizationSignup(
    $token: String!,
    $tokenIssuer: String!,
    $externalIdentifier: String!,
    $pdtUid: String!,
    $email: String!,
    $password: String!,
    $firstName: String!,
    $lastName: String!,
    $phone: String!,
    $dateOfBirth: DateTime,
    $region: String
  ) {
      externalOrganizationSignup(
        token: $token,
        tokenIssuer: $tokenIssuer,
        externalIdentifier: $externalIdentifier,
        pdtUid: $pdtUid,
        email: $email,
        password: $password,
        firstName: $firstName,
        lastName: $lastName,
        dateOfBirth: $dateOfBirth,
        phone: $phone,
        region: $region
      ) {
          success
          careTeam {
            patient {
              user {
                email
              }
            }
          }
      }
  }
`;

const ORGANIZATION_ACCESS_RENEWAL = gql`
  mutation ExternalOrganizationAccessRenewal(
    $token: String!,
    $tokenIssuer: String!,
    $externalIdentifier: String!,
    $pdtUid: String!,
  ) {
      externalOrganizationAccessRenewal(
        token: $token,
        tokenIssuer: $tokenIssuer,
        externalIdentifier: $externalIdentifier,
        pdtUid: $pdtUid,
      ) {
          success
      }
  }
`;

const KAISER_TOKEN_ISSUER_NAME = 'Kaiser Permanente';

const KaiserSignupPage : React.FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('claims');
  const { t } = useTranslation();
  const [decodeTokenError, setDecodeTokenError] = useState(false);
  const [tokenPayload, setTokenPayload] = useState<KaiserPayloadType>();
  const [accountCreated, setAccountCreated] = useState(false);
  const [accessRenewed, setAccessRenewed] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [createdUserEmail, setCreatedUserEmail] = useState<string>(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [decodeToken] = useMutation<MutationDecodeOrganizationSignupTokenArgs>(
    DECODE_TOKEN,
    { errorPolicy: 'all' },
  );
  const [createAccount] = useMutation<MutationExternalOrganizationSignupArgs>(
    ORGANIZATION_SIGNUP,
    { errorPolicy: 'all' },
  );
  const [renewAccess] = useMutation<MutationExternalOrganizationAccessRenewalArgs>(
    ORGANIZATION_ACCESS_RENEWAL,
    { errorPolicy: 'all' },
  );

  const processTokenOnMount = useCallback(async () => {
    const result = await decodeToken({
      variables: {
        token,
        tokenIssuer: KAISER_TOKEN_ISSUER_NAME,
        pdtUid: SPARKRX_PDT_UID,
      },
    });
    if (result.errors || !result.data) {
      setDecodeTokenError(true);
    } else {
      const responseData = result?.data?.decodeOrganizationSignupToken;
      const payload = responseData?.tokenPayload;
      const existingCareTeam = responseData?.existingCareTeam;
      let payloadJSON: KaiserPayloadType;
      if (payload) {
        payloadJSON = JSON.parse(payload);
        setTokenPayload(payloadJSON);
      }
      const existingUser = existingCareTeam?.patient?.user;
      const canAccessPdt = existingCareTeam?.canAccessPdt;
      setAccountCreated(!!existingUser);
      setEmailVerified(existingUser?.emailVerified || false);
      setCreatedUserEmail(existingUser?.email || null);

      if (existingUser && !canAccessPdt) {
        // Request access renewal
        const renewAccessResult = await renewAccess({
          variables: {
            token,
            tokenIssuer: KAISER_TOKEN_ISSUER_NAME,
            externalIdentifier: payloadJSON?.uid,
            pdtUid: SPARKRX_PDT_UID,
          },
        });
        if (renewAccessResult?.errors || !renewAccessResult?.data) {
          setDecodeTokenError(true);
        } else {
          setAccessRenewed(true);
        }
      }
    }
    setPageLoading(false);
  }, [tokenPayload]);

  useEffect(() => {
    processTokenOnMount();
  }, []);

  const onSubmit = useCallback(async (formData: KaiserSignupFormType) => {
    const {
      email, password, phone, firstName, lastName,
    } = formData;
    setFormLoading(true);
    const result = await createAccount({
      variables: {
        token,
        tokenIssuer: KAISER_TOKEN_ISSUER_NAME,
        pdtUid: SPARKRX_PDT_UID,
        dateOfBirth: new Date(tokenPayload.dob),
        email,
        password,
        phone,
        firstName,
        lastName,
        region: tokenPayload.reg,
        externalIdentifier: tokenPayload.uid,
      },
    });
    const resultData = result?.data?.externalOrganizationSignup;
    if (resultData?.success) {
      setAccountCreated(true);
      setCreatedUserEmail(resultData?.careTeam?.patient?.user?.email);
    }
    setFormLoading(false);
    return result;
  }, [tokenPayload]);

  const textContainer = (
    <Styled.TextContainer>
      <Text fontSize="48px" fontWeight="700" lineHeight="56px" marginBottom="24px">
        <span style={{ color: '#089A7E' }}>{t('kaiser_signup.spark')}</span>
        {' '}
        {t('kaiser_signup.access_for_seven_weeks')}
      </Text>
      <Text fontSize="20px" lineHeight="28px">
        {t('kaiser_signup.spark_description')}
      </Text>
    </Styled.TextContainer>
  );

  const renderSignupForm = useCallback(() => (
    <Styled.KaiserForm
      defaultEmail={parsePayloadEmail(tokenPayload?.eml)}
      defaultFirstName={tokenPayload?.fnm}
      defaultLastName={tokenPayload?.lnm}
      onSubmit={onSubmit}
      loading={formLoading}
    />
  ), [tokenPayload, formLoading]);

  let pageContent: React.ReactNode;
  if (pageLoading) {
    pageContent = <LoadingSpinner />;
  } else if (decodeTokenError) {
    pageContent = <InvalidURLMessage style={{ flex: 1 }} />;
  } else if (accessRenewed) {
    pageContent = <AccessRenewedMessage style={{ flex: 1 }} email={createdUserEmail} />;
  } else if (accountCreated && emailVerified) {
    pageContent = <AccountCreatedMessage style={{ flex: 1 }} email={createdUserEmail} />;
  } else if (accountCreated && !emailVerified) {
    pageContent = <VerifyEmailMessage style={{ flex: 1 }} email={createdUserEmail} />;
  } else {
    pageContent = (
      <>
        <Styled.SignupContentOuterContainer>
          <Styled.SignupContentContainer>
            <Styled.BackgroundGraphic
              src={KaiserSignupBackgroundGraphic}
              alt="kaiser-signup-background-graphic"
            />
            {textContainer}
            {renderSignupForm()}
          </Styled.SignupContentContainer>
        </Styled.SignupContentOuterContainer>
        <LabelingText />
      </>
    );
  }

  return (
    <Styled.PageContainer>
      <Styled.LogoBanner>
        <Styled.SparkRxLogo src={SparkDirectLogo} alt="spark-direct-logo" />
        <Styled.KaiserLogo src={KaiserPermanenteLogo} alt="kaiser-logo" />
      </Styled.LogoBanner>
      <Styled.PageContentContainer>
        {pageContent}
      </Styled.PageContentContainer>
    </Styled.PageContainer>
  );
};

export default KaiserSignupPage;
